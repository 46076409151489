import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterModel } from 'projects/ui-coface/src/app/commons/model/filter.model';
import { Paginator } from 'projects/ui-coface/src/app/commons/model/paginator.model';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import { ModalService } from 'projects/ui-coface/src/app/commons/services/dialog.service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CreateUserStaffEndpoint } from '../endpoints/create-user.endpoint';
import { ICreateUserStaff } from '../interface/create-user.interface';
import { ListUserModel } from '../model/list-user.model';

@Injectable({ providedIn: 'root' })
export class CreateUserStaffOperation {
  // nameFileExport: string = ''

  constructor(
    private userEndpoint: CreateUserStaffEndpoint,
    private dialog: ModalService
  ) {}

  public createUserStaff(body: ICreateUserStaff) {
    const buildBody = this.formCreateUserStaff(body);
    return this.userEndpoint.createUserStaff$(buildBody).pipe();
  }

  private formCreateUserStaff(form: ICreateUserStaff) {
    let obj: ICreateUserStaff = {
      firstname: form.firstname, //change
      email: form.email,
      officeId: form.officeId,
      languageId: form.languageId,
      supportLanguageId: form.supportLanguageId,
      countryId: form.countryId,
      roleId: form.roleId,
      phoneNumber: form.phoneNumber,
      mobileNumber: form.mobileNumber,
      lastname: form.lastname, //change
    };
    if (!form.supportLanguageId) delete obj.supportLanguageId;
    return obj;
  }

  public getUser(filter: FilterModel) {
    const buildBody = this.builderRequest(filter);
    return this.userEndpoint.getUsers$(buildBody).pipe(
      map((res) => {
        const content = res['content'];
        let paginator = Paginator.backBuild<ListUserModel>(res);
        if (content?.length) {
          const row = content.map((val) => ListUserModel.backBuild(val));
          paginator.Content = row;
        }
        return paginator;
      })
    );
  }

  private builderRequest(filter: FilterModel) {
    const rq = new GenericRequestConfig();
    rq.params = new HttpParams();
    rq.params = rq.params.set('page', filter.Page - 1).set('size', filter.Size);
    return rq;
  }

  public dowloadBasicForm(idBasicForm): string {
    // let nameForm: string = ''
    switch (idBasicForm) {
      case 1:
        return BASICFORM_ID.COMERCIAL_BASICO;
      // break;
      case 2:
        return BASICFORM_ID.COMERCIAL_PERSONA_NATURAL_NO_OBLIGADO_BASICO;
      case 3:
        return BASICFORM_ID.COMERCIAL_PERSONA_NATURAL_BASICO;
      case 4:
        return BASICFORM_ID.SERVICIO_BASICO;
      case 5:
        return BASICFORM_ID.SERVICIO_PERSONA_NATURAL_BASICO;
      case 6:
        return BASICFORM_ID.QUALIFICACION_FORNECEDORES;
      default:
        return '';
    }
    // console.log(nameForm);
    // return nameForm;
  }
}

export enum BASICFORM_ID {
  COMERCIAL_BASICO = 'Formulario de calificacion de proveedores Comercial_Básico',
  COMERCIAL_PERSONA_NATURAL_NO_OBLIGADO_BASICO = 'Formulario de calificacion de proveedores Comercial_Persona Natural No obligada_Básico',
  COMERCIAL_PERSONA_NATURAL_BASICO = 'Formulario de calificacion de proveedores Comercial_Persona Natural_Básico',
  SERVICIO_BASICO = 'Formulario de calificacion de proveedores Servicios_Básico',
  SERVICIO_PERSONA_NATURAL_BASICO = 'Formulario de calificacion de proveedores Servicios_Persona Natural_Básico',
  QUALIFICACION_FORNECEDORES = 'Formulario de Qualificação de Fornecedores',
}
