import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseCoface } from 'projects/ui-coface/src/app/commons/interface/response-coface.interface';
import { Paginator } from 'projects/ui-coface/src/app/commons/model/paginator.model';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { ICreateUserStaff } from '../interface/create-user.interface';
import { IListUser } from '../interface/list-user.interface';


@Injectable({ providedIn: 'root' })
export class CreateUserStaffEndpoint {
  public constructor(private http: HttpClient) {}

  public createUserStaff$(body: ICreateUserStaff) {
    const url = `${environment.urlBaseEndpointV2}/backoffice/coworkers`;
    return this.http.post<ICreateUserStaff>(url, body);
  }

	public getUsers$(filters: GenericRequestConfig){
		const url = `${environment.urlBaseEndpointV2}/backoffice/coworkers`;
    return this.http.get<Paginator<IListUser>>(url,filters);
	}
}
