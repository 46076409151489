import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { StoreName } from '../../../commons/enums/store-name.enum';


@Component({
  selector: 'coface-modal-terms-and-conditions-document-upload',
  templateUrl: './modal-terms-and-conditions-document-upload.component.html',
  styleUrls: ['./modal-terms-and-conditions-document-upload.component.scss']
})
export class ModalTermsAndConditionsDocumentUploadComponent implements OnInit {
  selectedOption1:string = "";
  selectedOption2:string = "";
  selectedOption3:string = "";
  disableBtnNext:boolean = true;
  @Output() eventEmitAcept = new EventEmitter<boolean>();
  @Output() eventEmitCloseModal = new EventEmitter<boolean>();
  dataTerms:any;
  constructor() {
   }

  ngOnInit(): void {
    this.getTermsAndCondition();
  }

  getTermsAndCondition(){
    let data:any;
    if (localStorage.getItem(StoreName.TERMS_AND_CONDITIONS) ) {
      data = JSON.parse(localStorage.getItem(StoreName.TERMS_AND_CONDITIONS) || '');
    }
    const obj = {
      'titleDedeclarationUnderstandingTerms' : data.declarationUnderstandingTerms[0],
      'parrafoDeclarationUnderstandingTerms': data.declarationUnderstandingTerms[1],
      'titleAuthorizationTransferDocuments' : data.authorizationTransferDocuments[0],
      'parrafoAuthorizationTransferDocuments': data.authorizationTransferDocuments[1],
      'titleMarketingInformation' : data.marketingInformation[0],
      'parrafoMarketingInformation': data.marketingInformation[1]
    }
    this.dataTerms = obj
  }

  onInputChange(){
    if (this.selectedOption1=="si"&&this.selectedOption2=="si") {
      this.disableBtnNext=false;
    }else{
      this.disableBtnNext=true;
    }
  }

  closeModal(){
    this.eventEmitCloseModal.emit(true);
  }

  acept(){
    let value = this.selectedOption2 === "si" ? true : false;
    this.eventEmitAcept.emit(value);
  }
}
