<div class="modal_success">
  <div class="text-coface-primary text-xl font-bold text-center">
    {{ message  | translate}}
  </div>
  <button
    class="btn__passWayPay w-full text-white bg-coface-primary mt-5 block mx-auto text-sm"
    (click)="closePOPUP()"
  >
    {{'Aceptar' | translate}}
  </button>
</div>
