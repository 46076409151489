import { Component, Input, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig} from 'primeng/dynamicdialog';
// import { DynamicDialogConfig } from 'primeng/dynamicdialog/dynamicdialog-config';

@Component({
  selector: 'coface-successful',
  templateUrl: './successful.component.html',
  styleUrls: ['./successful.component.scss'],
  providers: [DialogService],
})
export class SuccessfulComponent implements OnInit {
  message: string;
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.message = this.config.data;
    
  }

  closePOPUP(){
    this.ref.close();
  }
}