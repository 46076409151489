import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HOMOLOGATION_CONDITION } from 'projects/ui-coface/src/app/commons/enums/condition.enum';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import { ModalService } from 'projects/ui-coface/src/app/commons/services/dialog.service';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PendingRequestEndpoint } from '../endpoint/pending-request.endpoint';
import { ListPendingRequest } from '../model/pending-request.model';

@Injectable({
  providedIn: 'root',
})
export class PendingRequestOperation {
  nameSupplier: string;
  idCountrySupplier: number;
  constructor(
    private pendingRequestEndpoint: PendingRequestEndpoint,
    private session: SessionService,
    private dialog: ModalService
  ) {}

  getPendinRequest$() {
    const req = new GenericRequestConfig();
    req.url = `${environment.urlBaseEndpointV2}/homologation/endpoint/get-homologations-listed-by-user`;
    req.params = new HttpParams()
      .set('userId', this.session.user.id!) // this.session.user.id! 1
      .set('roleId', this.session.user.rolId!); // this.session.user.rolId! 5

    return this.pendingRequestEndpoint.getPendinRequest$(req).pipe(
      map((res) => {
        const content = res.data;
        let newData = content.map((elm) => ListPendingRequest.backBuild(elm));
        return newData;
      })
    );
  }

  public initHomologation$(homologationId: number) {
    const req = new GenericRequestConfig();
    const obj = {
      condition: {
        id: HOMOLOGATION_CONDITION.PENDIENTE_PAGO,
      },
    };
    req.body = obj;
    req.url = `${environment.urlBaseEndpointV2}/homologation/homologation-init/${homologationId}`;
    return this.pendingRequestEndpoint.initHomologation$(req).pipe();
  }

  public deniedHomlogation$(homologationId: number) {
    const req = new GenericRequestConfig();
    const obj = {
      condition: {
        id: HOMOLOGATION_CONDITION.RECHAZADO,
      },
    };
    req.body = obj;
    req.url = `${environment.urlBaseEndpointV2}/homologation/homologation-decline/${homologationId}`;
    return this.pendingRequestEndpoint.deniedHomlogation$(req).pipe();
  }

  public getTermAndConditions$() {
    const version = this.session.user.language?.id ?? 1;
    const url = `${environment.urlBaseEndpointV2}/terms-condition-content/${version}`;

    return this.pendingRequestEndpoint.getTermAndConditions$(url).pipe(
      map((res) => {
        return res.data;
      })
    );
  }
}
