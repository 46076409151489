import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CustomerOperation } from 'projects/ui-coface/src/app/modules/customer/commons/operations/customer.operations';

@Component({
  selector: 'coface-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  providers: [ CustomerOperation ],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private customerOpe: CustomerOperation
  ) {}

  ngOnInit(): void {}
  public onDelete() {
    this.customerOpe.deleteCustomers$(this.config.data).subscribe((_) => {
      this.ref.close();
    });
  }
  public onCancel() {
    this.ref.close('CANCEL');
  }
}
