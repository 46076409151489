import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { StoreName } from 'projects/ui-coface/src/app/commons/enums/store-name.enum';
import { dataPersonTermsAndConditions } from 'projects/ui-coface/src/app/commons/interface/dataPersonTermsAndConditions';
import { TermConditionOperation } from 'projects/ui-coface/src/app/commons/operations/term-condition.operation';

@Component({
  selector: 'coface-terms-and-conditions-person-natural',
  templateUrl: './terms-and-conditions-person-natural.component.html',
  styleUrls: ['./terms-and-conditions-person-natural.component.scss']
})
export class TermsAndConditionsPersonNaturalComponent implements OnInit {
  formPersonNatural!: UntypedFormGroup;
  selectedFile: File | null = null;
  nameFile:String="";
  dataTerms:any;
  @ViewChild('fileDocumentPerson') fileDocumentPerson: ElementRef;

  constructor(
    private dialogRef: DynamicDialogRef,
    private fb: UntypedFormBuilder,
    private serviceTermCondOperation:TermConditionOperation) { }

  ngOnInit(): void {
    this.initForm();
    this.getTermsAndCondition();
  }

  initForm(){
    this.formPersonNatural = this.fb.group({
      namePerson: ['', 
        [ Validators.maxLength(40),
          Validators.required
        ]
      ],
      fileDocumentIdentity: [null,[Validators.required]]
    }); 
  }

  getTermsAndCondition(){
    let data:any;
    if (localStorage.getItem(StoreName.TERMS_AND_CONDITIONS) ) {
      data = JSON.parse(localStorage.getItem(StoreName.TERMS_AND_CONDITIONS) || '');
    }
    const obj = {      
      'data' : data.contentForNaturalPerson
    }
    this.dataTerms = obj
  }

   // Método para manejar el cambio del archivo
   onFileChange(event: any) {    
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.nameFile = file.name;
      this.formPersonNatural.patchValue({
        file: file
      });
    }
  }

  
  onclickUploadFile() {
    this.fileDocumentPerson.nativeElement.click();
    this.formPersonNatural.get('fileDocumentIdentity')?.markAsTouched();
  }


  deleteFile(){
    this.selectedFile = null;
    this.formPersonNatural.patchValue({
      file: null
    });
    const name = this.formPersonNatural.get("namePerson")?.value;
    this.formPersonNatural.reset();
    this.formPersonNatural.patchValue({
      namePerson:name
    });
  }

  // Método para manejar el envío del formulario
  onSubmit() {
    if (this.formPersonNatural.valid && this.selectedFile) {
      const data: dataPersonTermsAndConditions = {
        typePerson: "Natural",
        nameLegalRepresentative: this.formPersonNatural.get('namePerson')?.value,
        Document: this.selectedFile
      }
      this.serviceTermCondOperation.dataPersonTermsAndConditions = data;
    } else {
    }
  }

  onAcceptTermCond() {
    this.dialogRef.close('COMPLETE');
    this.onSubmit();
  }

  buildImage() {
    let imageIcon = '';
    let threeLetters = this.selectedFile?.name.substring(this.selectedFile.name.length - 3);
    switch (threeLetters) {
      case 'ocx':
        imageIcon = TYPE_FILE.WORD;
        break;
      case 'pdf':
        imageIcon = TYPE_FILE.PDF;
        break;
      case 'lsx':
        imageIcon = TYPE_FILE.EXCEL;
        break;
      default:
        imageIcon = TYPE_FILE.OTRO;
        break;
    }
    return imageIcon;
  }  
}


enum TYPE_FILE {
  PDF = 'pdf',
  WORD = 'word',
  EXCEL = 'excel',
  OTRO = 'document',
}