import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { SessionService } from '../services/session.service';
import { Router } from '@angular/router';
import { Observable, catchError, of, throwError } from 'rxjs';
import { ModalService } from '../services/dialog.service';
import { ErrorComponent } from '../../shared/components/dialogs/error/error.component';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
  constructor(
    private session: SessionService,
    private router: Router,
    private dialog: ModalService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const tokenValue = this.session.Token;
    // console.log(req.headers.has('no-intercept'));

    if (tokenValue && !req.headers.has('no-intercept-token')) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${tokenValue}`),
      });
    }

    return next
      .handle(req)
      .pipe(catchError((error) => this.handlerError(error, req)));
  }

  handlerError(
    error: HttpErrorResponse,
    req: HttpRequest<any>
  ): Observable<any> {
    if (req.headers.has('no-intercept')) {
      return throwError(() => error);
    } else {
      this.dialog.open<ErrorComponent>(error, ErrorComponent, {
        size: 'SMALL_MEDIUM',
        disableClose: false,
      });
    }

    return throwError(() => error);
  }
}
