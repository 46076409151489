<div class="wrap-confirm py-7">
  <h2 class="text-xl text-center font-bold">
    Una vez que envíe los documentos, no podrá agregarlos o modificarlos
    posteriormente. Por favor, asegúrese de incluir todos los archivos
    necesarios antes de finalizar el envío
  </h2>
  <div class="flex mt-5 gap-10 items-center justify-between">
    <button
      class="btn__pass block w-full text-white bg-coface-primary hover:opacity-75 "
      (click)="onCancel()"
    >
      CANCELAR
    </button>
    <button
      class="btn__pass block w-full text-white bg-coface-primary hover:opacity-75 "
      (click)="onAccept()"
    >
      ACEPTAR
    </button>
  </div>
</div>
