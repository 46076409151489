<div class="welcome">
  <div class="text-center">
    <h1 class="text-coface-primary font-bold text-2xl">
      {{ "Hola" | translate }}, {{ nameSupplier }}
    </h1>
    <p class="my-4 text-sm">
      {{
        "COFACE es una compañía francesa con presencia física en más de 60 países, tenemos 70 años de experiencia y somos líder mundial en el Seguro de Crédito. Contamos con más de 80 millones de empresas a nivel mundial en nuestra base de datos"
          | translate
      }}.
      <!-- <br /> -->
    </p>
    <p class="my-4 text-sm">
      {{
        "Adicionalmente, somos especializados en certificación de empresas tanto nacionales como internacionales"
          | translate
      }}.
    </p>
    <div class="flex justify-between items-center relative">
      <hr class="absolute line" />
      <div
        class="font-bold underline text-white bg-coface-primary rounded-3xl py-1.5 px-4 mx-auto z-10 w-[275px] transition-all duration-150 shadow-lg shadow-indigo-500/40 cursor-pointer hover:bg-blue-900 hover:w-[280px]"
        (click)="downloadFile()"
      >
        {{ "Carta de Presentación de" | translate }}:
        {{ information?.legalName }}
      </div>
    </div>
  </div>

  <div class="text-center">
    <h3 class="my-4 font-bold">
      {{ "Sobre el proceso de Calificación de Proveedores" | translate }}:
    </h3>
    <p class="my-4 text-sm">
      {{
        "Nuestro proceso de calificación, nos permite conocer la experiencia de trabajo, el índice de fiabilidad y el comportamiento de los proveedores con otros clientes. Además, damos a conocer la solvencia financiera, constitución legal e infraestructura que tiene la empresa en la actualidad"
          | translate
      }}.
    </p>
  </div>

  <div>
    <!-- <p class="mb-4 text-sm">
      {{ "Haga clic" | translate }}
      <span
        class="text-coface-primary underline cursor-pointer"
        (click)="downloadFile()"
      >
        {{ "aquí" | translate }}</span
      >
      {{ "para descargar la carta de presentación de Cliente" | translate }}
    </p> -->
    <div class="welcome__services flex justify-between mb-4">
      <div class="blockOne text-coface-primary">
        <p class="mb-2 font-bold text-sm">
          {{
            "¿Por qué elegir Coface para su proceso de Calificación?"
              | translate
          }}
        </p>
        <ul class="font13 text-coface-primary">
          <li class="ml-5 mb-3">
            {{ "Recibir una copia del informe elaborado por" | translate }}
            <span class="font-semibold">COFACE</span>.
          </li>
          <li class="ml-5 mb-3">
            {{
              "Recibir su Carta de Calificación o Certificado con el resultado de su calificación"
                | translate
            }}.
          </li>
          <li class="ml-5 mb-3">
            {{ "Ser recomendados a los Clientes de" | translate }}
            <span class="font-semibold">COFACE</span>,
            {{
              "en el momento en que estos requieran la información de las empresas mejor calificadas"
                | translate
            }}.
          </li>
          <!-- <li class="ml-5 mb-3">
            Massa tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada
            proin.
          </li> -->
        </ul>
      </div>
      <div class="blockTwo text-coface-primary">
        <p class="mb-2 font-bold text-sm">
          {{ "Precios del" | translate }} <br />
          {{ "servicio" | translate }}
        </p>
        <div class="font13 text-coface-primary">
          <p class="mb-3">
            {{ "Usted puede escoger entre dos tarifas" | translate }}:
          </p>
          <div class="mb-3">
            <div class="flex items-center">
              <input
                (change)="changeFirstRate($event)"
                type="radio"
                class="mr-2"
                value="especifica"
                name="rate"
              />
              <span class="font-bold"
                >1. {{ "Tarifa Específica" | translate }}:</span
              >
              <div class="tooltip relative inline-block">
                <img
                  class="ml-1 w-4"
                  src="assets/img/circle-question.svg"
                  alt="Tooltip image"
                  rel="preload"
                  as="image"
                />
                <span class="tooltiptext">
                  Escribir algo aquí como siempre lo recuerdo
                </span>
              </div>
            </div>
            <p>
              {{ "Beneficios" | translate }}:
              {{
                "Aquí se califica únicamente para la empresa asignada"
                  | translate
              }}.
            </p>
            <p>
              {{ "Precio" | translate }}:
              <span *ngIf="getPriceByCountry"
                >{{ getPriceByCountry.currency | translate }}
                {{ getPriceByCountry?.priceSpecify }}
              </span>
            </p>
            <p>
              {{ "Forma de pago" | translate }}: {{ "contado" | translate }}
            </p>
          </div>

          <div class="mb-3">
            <div class="flex items-center">
              <input
                (change)="changeFirstRate($event)"
                type="radio"
                class="mr-2"
                value="general"
                name="rate"
              />
              <span class="font-bold"
                >2. {{ "Tarifa General" | translate }}:</span
              >
              <div class="tooltip relative inline-block">
                <img
                  class="ml-1 w-4"
                  src="assets/img/circle-question.svg"
                  alt="Tooltip image"
                  rel="preload"
                  as="image"
                />
                <span class="tooltiptext">
                  Escribir algo aquí como siempre lo recuerdo
                </span>
              </div>
            </div>
            <p>
              {{ "Beneficios" | translate }}:
              {{
                "Aquí se puede calificar para cualquier empresa del mercado"
                  | translate
              }}.
            </p>
            <p>
              Precio:
              <span *ngIf="getPriceByCountry"
                >{{ getPriceByCountry?.currency | translate }}
                {{ getPriceByCountry?.price }}</span
              >
            </p>
            <p>
              {{ "Forma de pago" | translate }}: {{ "contado" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="my-5 border-y-line py-3">
      <span class="font-bold font13 text-coface-primary">
        {{ "Seleccione su tipo de empresa" | translate }}:
      </span>

      <input
        (click)="selectedPersonNatural()"
        type="radio"
        class="ml-5 mr-2"
        value="general"
        name="personNatural"
        [checked]="isSelectedPersonNatural"
      />
      <label (click)="selectedPersonNatural()" for="personNatural"
        >Persona Natural</label
      >

      <input
        (click)="selectedPersonjuridic()"
        type="radio"
        class="ml-5 mr-2"
        value="general"
        name="personJuridic"
        [checked]="isSelectedPersonJuridic"
      />
      <label (click)="selectedPersonjuridic()" for="personJuridic"
        >Persona Jurídica</label
      >
    </div>

    <div class="welcome__policies">
      <p class="text-sm">
        <input
          type="checkbox"
          [formControl]="termCond"
          style="pointer-events: none"
        />
        {{ "Estoy de acuerdo con los" | translate }}
        <a
          (click)="onOpenModalTermCondition()"
          class="text-coface-primary font-bold underline cursor-pointer"
          >{{ "Términos y Condiciones del Servicio" | translate }}</a
        >
      </p>
    </div>

    <div class="mt-4 text-center">
      <button
        class="btn__passModal w-full text-white bg-coface-primary mb-1"
        [disabled]="!(termCond.valid && !authRate)"
        (click)="onAcceptAndContinueProccess()"
      >
        {{ "Acepto continuar con el proceso" | translate }}
      </button>
      <button
        class="btn__passModal w-full text-white bgNoAccept mt-1"
        (click)="onDeniedContinueProcess()"
      >
        {{ "No deseo participar en el proceso" | translate }}
      </button>
    </div>
  </div>
</div>
