import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAccountManager } from 'projects/ui-coface/src/app/commons/interface/menu.interface';
import { IResponseCoface } from 'projects/ui-coface/src/app/commons/interface/response-coface.interface';
import { Paginator } from 'projects/ui-coface/src/app/commons/model/paginator.model';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { Observable } from 'rxjs';
import {
  FormCustomer,
  GetMyManager,
  IListCustomer,
  IListCustomerResponse,
  IListCustomerResponseV2,
  IRange,
  Manager,
} from '../interface/customer.interface';

@Injectable({ providedIn: 'root' })
export class CustomerEndpoint {
  public constructor(private http: HttpClient) {}

  public newRange$(body: IRange): Observable<IRange> {
    const url = `${environment.urlBaseEndpoint}/score-policies`;
    return this.http.post<IRange>(url, body);
  }

  /**modify create customer  */
  public newCustomer$(body: FormCustomer.IRequestCustomer) {
    // Observable<IResponseCoface<any>>
    // const params = new HttpParams().set('uuid', uuidCustomer);
    const url = `${environment.urlBaseEndpoint}/backoffice/customers`;
    return this.http.post<any>(url, body);
    // return this.http.post<IResponseCoface<any>>(url, body);
  }

  public newCompany$(body: FormCustomer.ICompany) {
    const url = `${environment.urlBaseEndpoint}/companies`;
    return this.http.post<FormCustomer.ICustomer>(url, body);
  }

  public getCustomer$(request: GenericRequestConfig) {
    return this.http.get<IResponseCoface<Paginator<IListCustomerResponseV2>>>(
      request.url,
      request
    );
  }

  public updateCustomer$(body: FormCustomer.IRequestCustomer, id) {
    const url = `${environment.urlBaseEndpoint}/backoffice/customers/${id}`; ///commons/baba-yaga/
    return this.http.put<IListCustomer[]>(url, body);
  }

  public updateManager$(idCustomer, idManager) {
    let obj = {};
    const params = new HttpParams().set('target', idManager);
    const url = `${environment.urlBaseEndpoint}/commons/customers/${idCustomer}/managers`;
    return this.http.patch<IResponseCoface<Manager>>(url, obj, {
      params: params,
    });
  }

  public getMyManager$(idCustomer) {
    const url = `${environment.urlBaseEndpoint}/backoffice/customers/${idCustomer}/managers`;
    return this.http.get<IResponseCoface<GetMyManager>>(url);
  }

  public deleteCustomer$(id: Array<number>) {
    const url = `${environment.urlBaseEndpoint}/customers/batch/delete`;
    return this.http.delete<IListCustomer[]>(url, { body: id });
  }

  public getAccountManagers(officeId) {
    // console.log(officeId);

    let params = new HttpParams();
    if (officeId) {
      // console.log('entra');

      params = params.set('officeId', officeId);
    }

    return this.http.get<IResponseCoface<IAccountManager[]>>(
      `${environment.urlBaseEndpointV2}/managers`,
      { params }
    );
  }

  public attachmentFileCustomer$(body: FormData, idCustomer) {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    return this.http.post(
      `${environment.urlBaseEndpointV2}/customers/${idCustomer}/attachments`,
      body,
      { headers }
    );
  }

  // public getManagerForACustomer$(idCustomer, idAccount) {
  //   let body = {};
  //   const params = new HttpParams().set('target', idAccount);
  //   return this.http.post(
  //     `${environment.urlBaseEndpointV2}/commons/${idCustomer}/managers`,
  //     body,
  //     { params: params }
  //   );
  // }
  // public uploadMassive$(formData: FormData): Observable<IUploadFile> {
  //   const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
  //   const url = `${environment.urlBaseEndpoint}/files/save-bulk-upload`;
  //   return this.http.post<IUploadFile>(url, formData, { headers });
  // }

  // public uploadMassive$(formData: FormData): Observable<IUploadFile> {
  //   const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
  //   const url = `${environment.urlBaseEndpoint}/files/save-bulk-upload`;
  //   return this.http.post<IUploadFile>(url, formData, { headers });
  // }

  //up

  public newAttachmentFileLetter$(body: FormData, userId: number) {
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });
    return this.http.post(
      `${environment.urlBaseEndpointV2}/backoffice/customers/${userId}/documents`,
      body,

      { headers: headers }
    );
  }

  // public newAttachmentFileQuizLetter$(body: FormData) {
  //   const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
  //   return this.http.post(
  //     `${environment.urlBaseEndpointV2}/cache/customers/attachments`,
  //     body,
  //     { headers }
  //   );
  // }
}
