import { ICountry } from 'projects/ui-coface/src/app/auth/commons/interface/auth.interface';
import { MODULE_COLLAPSE } from 'projects/ui-coface/src/app/commons/enums/module-collapse.enum';
import {
  IAnalyst,
  ICountries,
} from 'projects/ui-coface/src/app/commons/interface/menu.interface';
import { IDetail } from 'projects/ui-coface/src/app/commons/interface/paginator-table.interface';
import { CofaceBuilder } from 'projects/ui-coface/src/app/commons/model/coface.builder';
import { Attachment } from '../../../homologacion/commons/homologacion-interface';
import {
  ICompanies,
  IDetailWalletCustomer,
  IGetScorePolicy,
  IListCustomerResponse,
  IListCustomerResponseV2,
  IRange,
} from '../interface/customer.interface';
import { EUnlockModule } from 'projects/ui-coface/src/app/commons/enums/unlock-module.enum';

export class CustomerList extends CofaceBuilder {
  private nationalId: string;
  private nameCompany: string;
  private nameTrade: string;
  private country: ICountries;
  private view: string;
  private detail: IDetail<IDetailWalletCustomer>;
  private countryName: string;
  private reporter: IAnalyst;
  private scorePolicy: IRange;
  private reportSalePrice: number;
  private id: number;
  private isActive: boolean;
  private password: string;
  private phoneNumber: string;
  private countryId: number;
  private idCompany: number;
  private office: ICountry;
  public get Office(): ICountry {
    return this.office;
  }
  public set Office(value: ICountry) {
    this.office = value;
  }
  public get IdCompany(): number {
    return this.idCompany;
  }
  public set IdCompany(value: number) {
    this.idCompany = value;
  }

  private idScore: number;
  public get IdScore(): number {
    return this.idScore;
  }
  public set IdScore(value: number) {
    this.idScore = value;
  }

  public get CountryId(): number {
    return this.countryId;
  }
  public set CountryId(value: number) {
    this.countryId = value;
  }

  public get PhoneNumber(): string {
    return this.phoneNumber;
  }
  public set PhoneNumber(value: string) {
    this.phoneNumber = value;
  }

  public check: boolean;
  private attachment: Attachment;
  private company: ICompanies;
  public get Company(): ICompanies {
    return this.company;
  }
  public set Company(value: ICompanies) {
    this.company = value;
  }
  public get Attachment(): Attachment {
    return this.attachment;
  }
  public set Attachment(value: Attachment) {
    this.attachment = value;
  }

  public get Password(): string {
    return this.password;
  }
  public set Password(value: string) {
    this.password = value;
  }
  public get IsActive(): boolean {
    return this.isActive;
  }
  public set IsActive(value: boolean) {
    this.isActive = value;
  }

  public get Id(): number {
    return this.id;
  }
  public set Id(value: number) {
    this.id = value;
  }

  public get ReportSalePrice(): number {
    return this.reportSalePrice;
  }
  public set ReportSalePrice(value: number) {
    this.reportSalePrice = value;
  }

  public get ScorePolicy(): IRange {
    return this.scorePolicy;
  }
  public set ScorePolicy(value: IRange) {
    this.scorePolicy = value;
  }

  public get Reporter(): IAnalyst {
    return this.reporter;
  }
  public set Reporter(value: IAnalyst) {
    this.reporter = value;
  }

  public get CountryName(): string {
    return this.countryName;
  }
  public set CountryName(value: string) {
    this.countryName = value;
  }
  public get NationalId(): string {
    return this.nationalId;
  }
  public set NationalId(value: string) {
    this.nationalId = value;
  }

  public get NameCompany(): string {
    return this.nameCompany;
  }
  public set NameCompany(value: string) {
    this.nameCompany = value;
  }

  public get NameTrade(): string {
    return this.nameTrade;
  }
  public set NameTrade(value: string) {
    this.nameTrade = value;
  }

  public get Country(): ICountries {
    return this.country;
  }
  public set Country(value: ICountries) {
    this.country = value;
  }

  public get View(): string {
    return this.view;
  }
  public set View(value: string) {
    this.view = value;
  }

  public get Detail(): IDetail<IDetailWalletCustomer> {
    return this.detail;
  }
  public set Detail(value: IDetail<IDetailWalletCustomer>) {
    this.detail = value;
  }

  public static buildScorePolicy(data: Array<IGetScorePolicy>) {
    return data.map((item) => {
      return {
        classification: item.classification,
        from: item.minRange,
        until: item.maxRange,
      };
    });
  }

  static override backBuild(data: IListCustomerResponseV2) {
    const formateando = new CustomerList();
    if (data) {
      formateando.Id = data.id;

      formateando.NationalId = data.nationalId;
      formateando.NameCompany = data.legalName;
      formateando.NameTrade = data.commercialName;
      formateando.CountryName = data.countryName;
      formateando.PhoneNumber = data.phoneNumber;
      formateando.CountryId = data.countryId;
      formateando.Office = {
        id: data.countryId,
        name: '',
        code: '',
        hasOffice: false,
      };
      formateando.Detail = {
        dataDetail: {
          attachment: data.attachments,
          address: data.address,
          email: data.email,
          name: data.contactName ?? '', //change
          phone: data.phoneNumber!,
          scorePolicy: this.buildScorePolicy(data.scorePolicies),
          deliveryTerm: data.reportDuration,
          periodoValidezFile: data.reportDuration,
          languageId: 2,
          downloadPresentationLetter: data.attachments.find(
            (x) => x.folderName === 'PRESENTATION_LETTERS'
          )?.id,
          downloadCustomForms: data.attachments.filter(
            (x) => x.folderName === 'CUSTOM_FORMS'
          ),
        },
        moduleType: MODULE_COLLAPSE.WALLET_CUSTOMER,
      };
      // formateando.IdScore = data.company?.scorePolicy?.id;
      // formateando.IdCompany = data.company?.id;
      formateando.ReportSalePrice = data.reportSalePrice;
      formateando['viewMore'] = 'COLLAPSE';
      formateando['blockedState'] =
        data.status === 'ACTIVE' ? 'Activo' : 'Bloqueado';
      formateando['blockedStatebtn'] = 'BLOCKED_STATE';
      formateando['unlockModule'] = EUnlockModule.MANAGER_CUSTOMER_WALLET;
      formateando['nameToUnlock'] = data?.legalName;
    }
    return formateando;
  }
}
