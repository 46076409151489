export enum TABLE_EVENTS {
  EDIT = 'EDIT',
  DOWNLOAD = 'DOWNLOAD',
  DOCUMENT = 'DOCUMENT',
  VIEW = 'VIEW',
  LENS = 'DETAIL',
  SELECT = 'SELECT',
  INPUT = 'INPUT',
}

export enum TABLE_EVENT_CHECK {
  ALL = 'ALL',
  ITEM = 'ITEM',
}
