<div class="flex justify-between mt-10">
  <div class="flex items-center">
    <button class="outline-none mr-5" (click)="onEmitEvent()" *ngIf="event">
      <img src="assets/img/upload/trash.svg" alt="" />
    </button>
    <select
      name="select-size"
      id="select-size"
      class="font-bold w-20 h-10 text-center outline-none"
      (change)="onItemsChange($event.target?.value)"
      [formControl]="itemsPerPage"
    >
      <option [value]="item.id" *ngFor="let item of items">
        {{ item.name }}
      </option>
    </select>
    &nbsp; Registros por Página
  </div>

  <div class="inline">
    <button
      [disabled]="currentPage === 0"
      type="button"
      class="bg-coface text-white font-bold w-10 h-10"
      (click)="backPage()"
    >
      &#x2190;
    </button>
    <input
      type="text"
      class="text-center outline-none mx-2 w-10 h-10"
      [value]="page"
      cofaceOnlyNumber
      (keyup.enter)="onKeyUp($event)"
    />
    de <label for="qty" class="font-bold">{{ totalPage }}</label>
    <button
      [disabled]="currentPage === totalPage - 1"
      type="button"
      class="ml-2 bg-coface text-white font-bold w-10 h-10"
      (click)="nextPage()"
    >
      &#x2192;
    </button>
  </div>
</div>
