import { UploadHttp } from './http/upload.http';
import { NgModule } from '@angular/core';

import { OnlyNumberDirective } from './directives/only-number.directive';
import { ValidateSpaceDirective } from './directives/validate-space.directive';
import { CofaceFormDirective } from './directives/reactive-form.directive';
import { NumberDirective } from './directives/number.directive';
import { UploadOperation } from './operations/upload.operations';
import { ReactiveFileDirective } from './directives/reactive-file.directive';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [
    OnlyNumberDirective,
    ValidateSpaceDirective,
    CofaceFormDirective,
    NumberDirective,
    ReactiveFileDirective,
  ],
  imports: [DynamicDialogModule],
  exports: [
    OnlyNumberDirective,
    ValidateSpaceDirective,
    CofaceFormDirective,
    NumberDirective,
    ReactiveFileDirective,
  ],
  providers: [UploadHttp, UploadOperation, DialogService],
})
export class CofaceCommonsModule {}
