<div class="wrap-confirm py-7">
  <h2 class="text-xl text-center font-bold">
    ¿Está seguro de eliminar el cliente?
  </h2>
  <div class="flex mt-5 items-center justify-between">
    <button
      class="btn__pass w-full text-white bg-coface-primary hover:opacity-75 max-w-sm"
			(click)="onCancel()"
    >
      CANCELAR
    </button>
    <button
      class="btn__pass w-full text-white bg-coface-primary hover:opacity-75 max-w-sm"
			(click)="onDelete()"
    >
      ACEPTAR
    </button>
  </div>
</div>
