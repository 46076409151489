<div
  class="input_file flex justify-between relative cursor-pointer bg-gray-100"
  *ngIf="!disableBtnFile"
>
  <input
    class="mb-5 text-start w-full"
    type="file"
    required
    id="fileUpload"
    autocomplete="off"
    #uploader
    hidden
    [accept]="textAccept"
    (change)="fileEvent($event)"
  />
  <button
    type="button"
    name="fileUpload"
    class="w-full"
    *ngIf="!showFileLoaded"
    (click)="uploader.click()"
  >
    <div class="flex justify-between items-center">
      <p>{{'Subir documento' | translate}}</p>
      <img
        class="block absolute w-4 right-6"
        src="assets/img/upload/upload.svg"
        alt="Logo Cliente"
        rel="preload"
        as="image"
      />
    </div>
  </button>

  <div *ngIf="showFileLoaded && flagEvent" class="flex justify-between items-center w-full">
    <div class="text-color">{{ fileLoaded }}</div>
    <button (click)="changeFileEvent()" type="button">
      <img
        class="block w-6 right-6"
        src="assets/img/cerrar-modal.svg"
        alt="Eliminar archivo"
        rel="preload"
        as="image"
      />
    </button>
  </div>
</div>
