import { Injectable, Type } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: DialogService) {}

  open<T>(
    data,
    component: Type<T>,
    options: {
      size: 'SMALL' | 'MEDIUM' | 'LARGE' | 'SMALL_MEDIUM';
      disableClose?: boolean;
      closable?: string;
    }
  ) {
    const ref = this.dialog.open(component, {
      data: data,
      showHeader: false,
      width: WIDTH_MODAL[options.size],
      dismissableMask:
        options.closable === 'NO_CLOSE_BY_OUTSIDE' ? false : true,
      transitionOptions: '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
      baseZIndex: 10000,
      contentStyle: { 'border-radius': '20px' },
      style: { 'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.1)' },
      closable: options.disableClose,
    });

    return ref;
  }
}

enum WIDTH_MODAL {
  SMALL = '25%',
  SMALL_MEDIUM = '35%',
  MEDIUM = '50%',
  LARGE = '50%',
}
