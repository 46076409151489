
<form [formGroup]="formRangeA">
  <div class="flex justify-between items-center">
    <p class="text-coface-primary font-bold">
      {{'Calificación' | translate}} {{ formRangeA.value.classification }}:
    </p>
    <p>{{'Desde' | translate}}</p>
    <div class="input_range shadow-white-900 shadow-lg">
      <input
        class="text-center"
        type="text"
        maxlength="3"
        autocomplete="off"
        formControlName="from"
        cofaceOnlyNumber
      />
    </div>
    <p>{{'Hasta' | translate}}</p>
    <div class="input_range shadow-white-900 shadow-lg">
      <input
        class="text-center"
        type="text"
        maxlength="3"
        autocomplete="off"
        formControlName="until"
        cofaceOnlyNumber
      />
    </div>
  </div>
</form>
