<div id="myModal" class="modal">
    <div class="modal__body">
      <a class="btn__close" (click)="closeModal()">
        <img class="btn__close__img" src="assets/img/cerrar-modal.svg" alt="" />
      </a>
      <div>
        <div class="modal__body__text">
          <h3 class="text-coface-primary font-bold text-xl mb-2">
            {{ "Términos y Condiciones" | translate }}
          </h3>
          <p class="text-sm text-modal cl-text-modal pt-3">
            {{dataTerms.titleDedeclarationUnderstandingTerms}}<br>
           {{dataTerms.parrafoDeclarationUnderstandingTerms}}
          </p>
          <div class="text-options cl-text-modal pb-5">
            <span class="mr-3"><strong>Autorizo:</strong></span>
            <label class="mr-3" [ngClass]="{'check-select': selectedOption1=='si'}" >
              <input type="radio" name="option" value="si" [(ngModel)]="selectedOption1" (ngModelChange)="onInputChange()"> Sí
            </label>
            <label [ngClass]="{'check-select': selectedOption1=='no'}">
              <input type="radio" name="option" value="no" [(ngModel)]="selectedOption1" (ngModelChange)="onInputChange()"> No
            </label>
          </div>

          <p class="text-sm text-modal cl-text-modal">
            {{dataTerms.titleAuthorizationTransferDocuments}}<br>
            {{dataTerms.parrafoAuthorizationTransferDocuments}}
          </p>          
          <div class="text-options cl-text-modal pb-5">
            <span class="mr-3 text-bold"><strong>Autorizo:</strong></span>
            <label class="mr-3" [ngClass]="{'check-select': selectedOption2=='si'}">
              <input type="radio" name="option2" value="si" [(ngModel)]="selectedOption2" (ngModelChange)="onInputChange()"> Sí
            </label>
            <label [ngClass]="{'check-select': selectedOption2=='no'}">
              <input type="radio" name="option2" value="no" [(ngModel)]="selectedOption2" (ngModelChange)="onInputChange()"> No
            </label>
          </div>

          <p class="text-sm text-modal cl-text-modal">
            {{dataTerms.titleMarketingInformation}}<br>
            {{dataTerms.parrafoMarketingInformation}}
          </p>
          <div class="text-options cl-text-modal pb-5">
            <span class="mr-3 text-bold"><strong>Autorizo:</strong></span>
            <label class="mr-3" [ngClass]="{'check-select': selectedOption3=='si'}">
              <input type="radio" name="option3" value="si" [(ngModel)]="selectedOption3"> Sí
            </label>
            <label [ngClass]="{'check-select': selectedOption3=='no'}">
              <input type="radio" name="option3" value="no" [(ngModel)]="selectedOption3"> No
            </label>
          </div>
        </div>
        
        <button
          [disabled]="disableBtnNext"
          class="btn__accept w-full text-white bg-coface-primary hover:opacity-75 cursor-pointer"
          (click)="acept()"
        >
          {{'Aceptar' | translate}}
        </button>
      </div>
    </div>
  </div>
  