import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { Parameter } from '../../../commons/model/parameter.model';

@Component({
  selector: 'coface-autocomplete',
  templateUrl: './autocomplete-control.component.html',
  styleUrls: ['./autocomplete-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteControlComponent),
      multi: true,
    },
  ],
})
export class AutocompleteControlComponent
  implements ControlValueAccessor, OnInit
{
  @Input() public suggestionsData: Array<Parameter> = [];
  @Input() public loading: boolean;
  @Input() public placeholder: string = 'Ingresa nombre del cliente';
  @Input() public showIcon: boolean = false;
  @Input() public shadow: boolean = false;

  @ViewChild('autoCompleteTemplate', { static: false })
  public autoCompleteTemplate: ElementRef;
  @ViewChild('refInput', { static: false }) public refInput: ElementRef;

  public showSuggestions: boolean = false;
  public value: string = '';
  public suggestions: Array<Parameter> = [];
  public isSelectedSuggestion: boolean = false;
  public id: string;

  private onChange: (value: any) => void;
  private onTouched: () => void;

  constructor() {}

  public ngOnInit(): void {
    this.suggestions = this.suggestionsData;
  }

  public onFocusInput() {
    this.showSuggestions = true;
    if (this.id) {
      this.suggestions = this.suggestionsData.filter((x) => x.Id === this.id);
    }
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (!this.autoCompleteTemplate.nativeElement.contains(event.target)) {
      if (this.showSuggestions && !this.isSelectedSuggestion) {
        this.refInput.nativeElement.value = '';
        this.onChange('');
        this.value = '';
        this.id = '';
        this.suggestions = this.suggestionsData;
      }
      this.isSelectedSuggestion = false;
      this.showSuggestions = false;
    }
  }

  public onSearchData(event) {
    this.onChange('');
    this.id = '';
    this.isSelectedSuggestion = false;
    this.suggestions = this.suggestionsData.filter((x) =>
      x.Name.toLowerCase().includes(event.target.value.toLowerCase())
    );
  }

  public selectSuggestion(selectSuggestion: Parameter) {
    this.isSelectedSuggestion = true;
    this.value = selectSuggestion.Name;
    this.onChange(selectSuggestion.Id);
    this.id = selectSuggestion.Id;
    this.onTouched();
    this.showSuggestions = false;
  }

  public registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public writeValue(value) {
    if (value) {
      const findSuggestion = this.suggestionsData.find((x) => x.Id === value);
      this.value = findSuggestion?.Name!;
      this.suggestions = this.suggestionsData.filter(
        (x) => x.Id === findSuggestion?.Id
      );
    }
  }
}