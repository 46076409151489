
export class Parameter {
	public set Description(x: string) {
		this.description = x;
	}

	public get Description(): string {
		return this.description;
	}
	private description: string;

	public set Disable(x: boolean) {
		this.disable = x;
	}

	public get Disable() {
		return this.disable;
	}
	private disable: boolean;

	public set Id(x: string) {
		this.id = x;
	}

	public get Id() {
		return this.id;
	}
	private id: string;

	public set Name(x: string) {
		this.name = x;
	}

	public get Name() {
		return this.name;
	}
	private name: string;
	public set LastName(x: string) {
		this.lastName = x;
	}

	private firstname: string;
	public get Firstname(): string {
		return this.firstname;
	}
	public set Firstname(value: string) {
		this.firstname = value;
	}

	public get LastName() {
		return this.lastName;
	}
	private lastName: string;

	public constructor(id?: string, name?: string, lastnameOne?:string, description?: string, disable: boolean = false, firstname?: string) {

		this.Id = id!;
		this.name = name!;
		this.description = description!;
		this.lastName = lastnameOne!;
		this.disable = disable;
		this.firstname = name!
	}

}

export interface IParameter {
	description: string;
	name: string;
}
