import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { HomologacionOperation } from '../../../modules/homologacion/commons/homologacion-operation';
import { CreateUserStaffOperation } from '../../../modules/user-management/commons/operations/create-user.operations';
import { SessionService } from '../../../commons/services/session.service';

@Component({
  selector: 'coface-collapse-detail-table',
  templateUrl: './collapse-detail-table.component.html',
  styleUrls: ['./collapse-detail-table.component.scss'],
})
export class CollapseDetailTableComponent implements OnInit {
  @Input() detail;
  constructor(
    private homologationOperation: HomologacionOperation,
    private createUserStaffOperation: CreateUserStaffOperation,
    private session: SessionService
  ) {}

  ngOnInit(): void {}

  downloadCustomLetters(attachmentId: any) {
    if (attachmentId != null) {
      window.open(
        `${environment.urlBaseEndpoint}/attachments/download/${attachmentId}`
      );
    }
  }

  downloadCustomForms(attachments: any) {
    if (attachments.length) {
      for (const element of attachments) {
        window.open(
          `${environment.urlBaseEndpoint}/attachments/download/${element.id}`
        );
      }
    }
  }
}
