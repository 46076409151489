import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SuccessfulComponent } from '../dialogs/successful/successful.component';

@Component({
  selector: 'coface-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnInit, AfterViewInit, OnChanges {
  showFileLoaded: boolean = false;
  fileLoaded: string;
  disableBtnFile: boolean;
  @Input() flagEvent: boolean = false;
  @Output() selectedFile: any = new EventEmitter<any>();
  @Input() optionalParameter: any;
  @Input() textAccept?: string;

  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {}
  changeFileEvent() {
    if (this.showFileLoaded) {
      this.showFileLoaded = !this.showFileLoaded;
      //   this.singleUploadPresenter.coverLetter.reset('');
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes['optionalParameter'].currentValue);
    const change = changes['optionalParameter']?.currentValue;
    if (
      change &&
      (change.id === 7 || change.id === 8) &&
      change.attachments.length
    ) {
      this.disableBtnFile = true;
      return;
    } else {
      this.disableBtnFile = false;
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  fileEvent(e: Event) {
    let file = (<HTMLInputElement>e.target).files;
    if (file != null) {
      if (!this.doesNotHaveConsecutiveSpecialCharacters(file[0]!.name)) {
        this.dialogService.open(SuccessfulComponent, {
          data: 'Considerar que todo nombre de archivo a cargar no debe llevar ningún símbolo o caracter especial al final. Ejemplo: . , ; : - / (S.A./C.V./S.R.L.)',
          showHeader: false,
          width: '35%',
          dismissableMask: true,
          contentStyle: { 'border-radius': '15px' },
          baseZIndex: 10000,
        });
      } else {
        this.selectedFile.emit(file);
        this.fileLoaded = file[0]!.name;
        if (this.flagEvent == false) {
          this.showFileLoaded = true;
        }
        {
          this.showFileLoaded = false;
        }
      }
    }
  }

  doesNotHaveConsecutiveSpecialCharacters(filename) {
    // Regular expression pattern to match filenames without consecutive special characters
    const pattern =
      /^(?!.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{2})[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]+$/;

    return pattern.test(filename);
  }
}

// function doesNotHaveConsecutiveSpecialCharacters(filename) {
//   // Regular expression pattern to match filenames without consecutive special characters
//   const pattern = /^(?!.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{2})[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;

//   return pattern.test(filename);
// }

// // Example usage
// const filenames = [
//   "file_with_special!.txt",
//   "no_special_character.txt",
//   "file_with__consecutive_special_characters.txt",
//   "file_with_single_special_character.txt"
// ];

// filenames.forEach(filename => {
//   const isValid = doesNotHaveConsecutiveSpecialCharacters(filename);
//   console.log(`${filename} has consecutive special characters: ${!isValid}`);
// });
