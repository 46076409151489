<div class="overflow-hidden rounded-md drop-shadow-md">
  <table class="table-auto table-auto-rounded w-full">
    <thead>
      <tr>
        <th *ngIf="multiCheck" class="text-left">
          <input (change)="emitAllCheck($event.target)" type="checkbox" />
        </th>
        <ng-container *ngFor="let row of dataHead; let idx = index">
          <th *ngIf="row.name != 'check'">
            {{ row.name | translate }}
          </th>
        </ng-container>
        <th *ngIf="actions.length">{{ "Acciones" | translate }}</th>
      </tr>
    </thead>

    <tbody id="tbody" *ngIf="!loadingContent; else noContent">
      <ng-container *ngFor="let row of dataBody; let i = index" id="rowPrimary">
        <!-- {{ row | json }} -->
        <tr>
          <td *ngIf="multiCheck">
            <input
              (change)="emitItemCheck($event.target, row)"
              type="checkbox"
              [checked]="row.check"
            />
          </td>
          <ng-container *ngFor="let data of dataHead">
            <td
              *ngIf="
                row[data.id] !== 'COLLAPSE' && row[data.id] !== 'BLOCKED_STATE'
              "
            >
              <span class="text-gray-500">
                {{ row[data.id] }}
              </span>
            </td>

            <td
              (click)="onExpand(i)"
              class="cursor-pointer underline"
              *ngIf="row[data.id] === 'COLLAPSE'"
            >
              {{ "Ver más" | translate }}
            </td>
            <td
              (click)="onOpenBlockedStatus(row)"
              class="cursor-pointer underline"
              *ngIf="row[data.id] === 'BLOCKED_STATE'"
              [ngClass]="{
                clRed: row.blockedState !== 'Activo',
                clGreen: row.blockedState === 'Activo'
              }"
            >
              {{ row.blockedState | translate }}
            </td>
          </ng-container>

          <!-- <td *ngIf="row.selectAction === 'SELECT'">
            <p-dropdown class="pdg-0" [options]="cities" optionLabel="name">
            </p-dropdown>
          </td> -->
          <!-- *image* -->
          <td *ngIf="actions.length">
            <div class="flex justify-around items-center">
              <ng-container *ngFor="let img of actions">
                <img
                  (click)="eventAction(row, img.event)"
                  src="assets/img/upload/{{ img.type }}"
                  alt=""
                  class="w-8 h-8 cursor-pointer"
                />
              </ng-container>
            </div>
          </td>
        </tr>

        <tr id="detail-{{ i }}" class="none">
          <td colspan="12">
            <ng-container
              [ngTemplateOutlet]="simple"
              [ngTemplateOutletContext]="{ details: row.detail }"
            >
            </ng-container>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="dataBody.length == 0" class="text-center">
        <tr class="d-block m-auto">
          <td colspan="12" class="text-lg p-10">
            {{ "Sin información" | translate }}
          </td>
        </tr>
      </ng-container>
    </tbody>
    <ng-template #noContent>
      <tr>
        <td colspan="12">
          <img
            src="assets/img/upload/spinner-loader.svg"
            alt="Cargando"
            rel="preload"
            as="image"
            class="w-15 mx-auto"
          />
        </td>
      </tr>
    </ng-template>
  </table>
</div>

<coface-paginator
  *ngIf="!loadingContent && totalElements"
  (changeItems)="onChangeTableSize($event)"
  (changePage)="onChangeTablePage($event)"
  (eventPaginator)="onEventPaginator()"
  [totalElements]="totalElements"
  [currentPage]="currentPage"
  [itemsXPage]="itemsXPage"
  [event]="evtPaginator"
></coface-paginator>

<ng-template #simple let-details="details">
  <coface-collapse-detail-table
    [detail]="details"
  ></coface-collapse-detail-table>
</ng-template>
