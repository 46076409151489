import { Injectable } from '@angular/core';

import { ILanguages, IUser } from '../../auth/commons/interface/auth.interface';
import { LanguageService } from '../../core/services/language.service';
import { IUserSession } from '../interface/user-session.interface';
// import { SessionStateModel } from '../model/session-model';
const TOKEN = 'TOKEN';
const USER_DATA = 'USER';
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private userData: IUser;
  private token: string;
  public constructor(public languageService: LanguageService) {}

  private builderUserData(tokenValue: IUser): Partial<IUser> {
    if (Object.values(tokenValue.role!).length === 0) {
      alert('OBJETO ROL VACIO');
      throw 'OBJETO ROL VACIO';
    }
    if (!tokenValue.role?.id) {
      alert('ROLE ID UNDEFINED OR NULL');
      throw 'ROLE ID UNDEFINED OR NULL';
    }

    this.userData = {
      email: tokenValue.email,
      id: tokenValue.id,
      lastname: tokenValue?.lastname, // change
      // fullName: (tokenValue?.firstname + ' ' + tokenValue?.lastname!) || '',
      fullName: tokenValue?.lastname
        ? tokenValue?.firstname + ' ' + tokenValue?.lastname!
        : tokenValue?.firstname,
      initialNames: tokenValue?.lastname
        ? tokenValue?.firstname![0] + tokenValue?.lastname![0]
        : tokenValue?.firstname![0], // change
      role: tokenValue.role,
      firstname: tokenValue.firstname,
      language: tokenValue.language,
      // rol: tokenValue.roles?.map((rol) => this.parseRolName(rol.keyword))[0],
      rolId: tokenValue.role?.id,
      company: tokenValue.company,
      office: {
        id: tokenValue.office?.id!,
        code: tokenValue.office?.code!,
        name: tokenValue.office?.name!,
        hasOffice: tokenValue.office?.hasOffice!,
      },
    };

    return this.userData;
  }

  public saveTokenToStorage(token, dataUser: IUser) {
    if (token) {
      this.token = token;
      sessionStorage.setItem(TOKEN, this.token);
    }
    // dataUser.role = {} as any;
    const buildData = this.builderUserData(dataUser);

    // console.log('SET USER DATA', buildData);

    sessionStorage.setItem(USER_DATA, JSON.stringify(buildData));
  }

  public changeLanguage(obj: ILanguages) {
    this.userData.language = { id: obj.id, name: obj.name, code: obj.code };
    sessionStorage.setItem(USER_DATA, JSON.stringify(this.userData));
  }

  public validateSessionToken() {
    const token = sessionStorage.getItem(TOKEN);
    const userData = JSON.parse(sessionStorage.getItem(USER_DATA) as string);
    this.token = token!;
    if (token && userData) {
      this.userData = userData;
      this.languageService.sendMessage(this.userData.language?.code!);
      return true;
    } else {
      return false;
    }
  }

  public destroySession() {
    sessionStorage.clear();
    this.userData = {} as IUserSession;
    this.token = '';
  }

  get user(): IUser {
    return this.userData;
  }
  get Token(): string {
    return this.token;
  }

  private parseRolName(rol: string): string {
    let rolName: string = '';
    switch (rol) {
      case 'STAFF':
        return (rolName = 'Administrador');
      case 'SUPPLIER':
        return (rolName = 'Proveedor');
      case 'CUSTOMER':
        return (rolName = 'Cliente');

      default:
        rolName = 'Administrador';
        break;
    }
    return rolName;
  }
}
