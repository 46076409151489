<div class="way-pay">
  <div>
    <h1 class="text-center text-2xl mb-3 clight font-bold">
      {{ "Detalles de la forma de pago" | translate }}
    </h1>
    <div
      class="flex justify-between text-sm"
      *ngFor="let item of informationPayed"
    >
      <div style="width: 48%">
        <p class="font-bold">{{ item.key | translate }}</p>
      </div>
      <div class="text-right" style="width: 48%">
        <p>{{ item.value }}</p>
      </div>
    </div>
    <div class="flex justify-between items-center relative my-4">
      <hr class="absolute line" />
      <div
        class="font-bold text-white bglight rounded-3xl py-1.5 px-4 z-10 text-sm"
      >
        {{ "Instrucciones para el proveedor" | translate }}
      </div>
    </div>

    <ul class="text-sm">
      <li>
        {{
          "Sigue las indicaciones que te brinda la plataforma para el llenado de los formularios y la carga de los documentos"
            | translate
        }}.
      </li>
      <li>
        {{
          "Te recomendamos registrar la documentación más actualizada que tengas, a fin de que nuestra evaluación sea lo más certera posible y tu cliente pueda contar con tu mejor calificación posible"
            | translate
        }}.
      </li>
      <li>
        {{
          "Recuerda de registrar en la plataforma tu comprobante de pago del servicio, a fin de que pueda ser validado oportunamente y puedas descargar de manera inmediata tu reporte y evaluación tan pronto esta sea emitida por Coface"
            | translate
        }}.
      </li>
    </ul>

    <div class="flex justify-between items-center relative my-4">
      <hr class="absolute line" />
      <div
        class="font-bold text-white bglight rounded-3xl py-1.5 px-4 z-10 text-sm"
      >
        {{ "Medio de contacto de Coface" | translate }}
      </div>
    </div>
    <p class="text-sm text-center">
      {{
        "En caso de alguna duda o consulta puede contactar al Account Manager asignado para su atención"
          | translate
      }}:
    </p>
    <ul class="text-sm">
      <li>
        <span class="text-coface-primary">
          {{ nameAccountManager }}, {{ emailAccountManager }},
          {{ phoneAccountManager }}
        </span>
      </li>
      <li>
        <span class="text-coface-primary">
          Jean Cornejo, jean.cornejo&#64;coface.com, 947 313 421
        </span>
      </li>
    </ul>
    <button
      (click)="onContinueProccess()"
      class="btn__passWayPay w-full text-white bg-coface-primary mt-5 block mx-auto text-sm"
    >
      {{ "Continuar con el proceso" | translate }}
    </button>
  </div>
</div>
