<div class="create-user">
  <div>
    <div
      class="create-user__title flex items-center justify-between relative py-4"
    >
      <div class="flex items-center">
        <img
          src="assets/img/upload/poligono-green.svg"
          alt="Poligono icono"
          rel="preload"
          as="image"
          class="w-3 mr-2"
        />
        <p class="font-bold arrow-right">{{ "Nuevo Usuario" | translate }}</p>
      </div>
      <p>
        <img
          src="assets/img/upload/clean.svg"
          alt="Limpiar formulario"
          rel="preload"
          as="image"
          class="w-6 cursor-pointer"
          (click)="btnClean()"
        />
      </p>
    </div>
    <form class="w-full" [formGroup]="createUserPresenter.Form">
      <div class="border-t py-4 border-gray-200 py-12">
        <div class="flex justify-between mb-5">
          <div style="width: 48%">
            <input
              class="input"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'Nombres' | translate }}"
              formControlName="firstname"
              [ngClass]="{ borderCustom: validInput('firstname') }"
            />
            <p
              *ngIf="validInput('firstname')"
              class="text-red-900 text-sm font-semibold w-full mt-1 ml-1"
            >
              {{ "Campo obligatorio" | translate }}.
            </p>
          </div>

          <div style="width: 48%">
            <input
              class="input"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'Apellido Paterno' | translate }}"
              formControlName="lastname"
              [ngClass]="{ borderCustom: validInput('lastname') }"
            />
            <p
              *ngIf="validInput('lastname')"
              class="text-red-900 text-sm font-semibold w-full mt-1 ml-1"
            >
              {{ "Campo obligatorio" | translate }}.
            </p>
          </div>
        </div>

        <div class="flex justify-between mb-5">
          <!-- <div style="width: 48%">
            <input
              class="input"
              type="text"
              required
              autocomplete="off"
              placeholder="Apellido Materno"
              formControlName="surname2"
              [ngClass]="{ borderCustom: validInput('surname2') }"
            />
            <p
              *ngIf="validInput('surname2')"
              class="text-red-900 text-sm font-semibold w-full mt-1 ml-1"
            >
              Campo obligatorio.
            </p>
          </div> -->

          <div style="width: 100%">
            <input
              class="input"
              type="email"
              required
              autocomplete="off"
              placeholder="{{ 'Correo electrónico' | translate }}"
              formControlName="email"
              [ngClass]="{
                'input-error': createUserPresenter.hasErrorEmailControl()
              }"
              cofaceValidateSpace="true"
            />
            <p
              *ngIf="createUserPresenter.hasErrorEmailControl()"
              class="text-red-900 text-sm font-semibold w-full mt-1 ml-1"
            >
              {{ "Se necesita un correo válido" | translate }}.
            </p>
          </div>
        </div>

        <div class="mb-5" *ngIf="false">
          <div class="flex justify-between">
            <div style="width: 48%">
              <input
                class="input"
                type="password"
                required
                autocomplete="off"
                placeholder="{{ 'Contraseña' | translate }}"
                formControlName="password"
                cofaceValidateSpace="true"
                maxlength="20"
                [ngClass]="{
                  borderCustom:
                    forms['password'].touched &&
                    forms['password'].hasError('required')
                }"
              />
            </div>
            <div style="width: 48%">
              <input
                class="input"
                type="password"
                required
                autocomplete="off"
                placeholder="{{ 'Confirmar contraseña' | translate }}"
                cofaceValidateSpace="true"
                maxlength="20"
                formControlName="repeatPassword"
                [ngClass]="{
                  borderCustom:
                    forms['repeatPassword'].touched &&
                    forms['repeatPassword'].hasError('required')
                }"
              />
              <!-- <div
                *ngIf="
                  forms['repeatPassword'].touched &&
                  forms['repeatPassword'].hasError('required')
                "
                class="text-red-900 text-sm font-semibold w-full mt-1 ml-1"
              >
                {{ "Campo obligatorio" | translate }}.
              </div> -->
            </div>
          </div>
          <!-- <div
            *ngIf="
              forms['repeatPassword'].hasError('minlength') ||
              forms['repeatPassword'].hasError('password')
            "
            class="text-red-900 font-semibold text-sm w-full mt-1 ml-1"
          >
            {{ "Debe contener más de 11 caracteres" | translate }}
          </div> -->
        </div>
        <div
          *ngIf="false"
          class="flex flex-col justify-center bg-gray-100 border border-solid p-5 mb-5 text-sm"
        >
          <div class="flex text-left mb-2">
            <p class="w-1/2 flex gap-2 items-center">
              <img
                class="w-5"
                src="assets/img/form/check-inactive.svg"
                alt="check-inactive"
                *ngIf="forms['password'].hasError('LOWERCASE')"
              />
              <img
                class="w-5"
                src="assets/img/form/check-active.svg"
                alt="check-active"
                *ngIf="!forms['password'].hasError('LOWERCASE')"
              />
              Al menos una letra minúscula [a-z]
            </p>
            <p class="w-1/2 flex gap-2 items-center">
              <img
                class="w-5"
                src="assets/img/form/check-inactive.svg"
                alt="check-inactive"
                *ngIf="forms['password'].hasError('DIGITNUMBER')"
              />
              <img
                class="w-5"
                src="assets/img/form/check-active.svg"
                alt="check-active"
                *ngIf="!forms['password'].hasError('DIGITNUMBER')"
              />
              Al menos un número [0-9]
            </p>
          </div>
          <div class="flex text-left mb-2">
            <p class="w-1/2 flex gap-2 items-center">
              <img
                class="w-5"
                src="assets/img/form/check-inactive.svg"
                alt="check-inactive"
                *ngIf="forms['password'].hasError('UPPERCASE')"
              />
              <img
                class="w-5"
                src="assets/img/form/check-active.svg"
                alt="check-active"
                *ngIf="!forms['password'].hasError('UPPERCASE')"
              />
              Al menos una letra mayúscula [A-Z]
            </p>

            <p class="w-1/2 flex gap-2 items-center">
              <img
                class="w-5"
                src="assets/img/form/check-inactive.svg"
                alt="check-inactive"
                *ngIf="forms['password'].hasError('SPECIALCHARACTER')"
              />
              <img
                class="w-5"
                src="assets/img/form/check-active.svg"
                alt="check-active"
                *ngIf="!forms['password'].hasError('SPECIALCHARACTER')"
              />
              Al menos un carácter especial [ejemplo: #-_!()]
            </p>
          </div>

          <div class="flex text-left mb-2">
            <p class="w-1/2 flex gap-2 items-center">
              <img
                class="w-5"
                src="assets/img/form/check-inactive.svg"
                alt="check-inactive"
                *ngIf="createUserPresenter.Form?.hasError('confirmedValidator')"
              />
              <img
                class="w-5"
                src="assets/img/form/check-active.svg"
                alt="check-active"
                *ngIf="
                  !createUserPresenter.Form?.hasError('confirmedValidator')
                "
              />
              Las contraseñas no coinciden
            </p>

            <p class="w-1/2 flex gap-2 items-center">
              <img
                class="w-5"
                src="assets/img/form/check-inactive.svg"
                alt="check-inactive"
                *ngIf="forms['password'].hasError('LENGTH')"
              />
              <img
                class="w-5"
                src="assets/img/form/check-active.svg"
                alt="check-active"
                *ngIf="!forms['password'].hasError('LENGTH')"
              />
              Mínimo 12 caracteres
            </p>
          </div>
        </div>

        <div class="flex justify-between mb-5">
          <div style="width: 48%">
            <div class="relative z-40">
              <coface-select
                id-select="select-office"
                id-option="option-office"
                cofaceReactiveForm
                [items]="itemsOffice"
                [valueTitle]="title1 | translate"
                formControlName="officeId"
                [value]="createUserPresenter.officeId.value"
              ></coface-select>
            </div>
          </div>

          <div style="width: 48%">
            <div class="relative z-40">
              <coface-select
                id-select="select-languaje"
                id-option="option-languaje"
                cofaceReactiveForm
                [items]="itemsLanguages"
                [valueTitle]="title2 | translate"
                [value]="createUserPresenter.languageId.value"
                formControlName="languageId"
              ></coface-select>
            </div>
          </div>
        </div>

        <div class="flex justify-between mb-5">
          <div style="width: 48%">
            <div class="relative z-30">
              <coface-select
                id-select="select-status"
                id-option="option-status"
                cofaceReactiveForm
                [items]="itemsStatus"
                [valueTitle]="title3 | translate"
                [value]="createUserPresenter.countryId.value"
                formControlName="countryId"
              ></coface-select>
            </div>
          </div>
          <div style="width: 48%">
            <input
              class="input"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'Teléfono' | translate }}"
              formControlName="phoneNumber"
              cofaceValidateSpace="true"
              cofaceOnlyNumber="true"
              maxlength="15"
              [ngClass]="{
                borderCustom:
                  forms['phoneNumber'].touched &&
                  forms['phoneNumber'].hasError('required')
              }"
            />
            <div
              *ngIf="
                forms['phoneNumber'].touched &&
                forms['phoneNumber'].hasError('required')
              "
              class="text-red-900 text-sm font-semibold w-full mt-1 ml-1"
            >
              {{ "Campo obligatorio" | translate }}
            </div>
          </div>
        </div>

        <div class="flex justify-between mb-5">
          <div style="width: 48%">
            <input
              class="input"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'Teléfono móvil' | translate }}"
              formControlName="mobileNumber"
              cofaceValidateSpace="true"
              cofaceOnlyNumber="true"
              maxlength="15"
              [ngClass]="{
                borderCustom:
                  forms['mobileNumber'].touched &&
                  forms['mobileNumber'].hasError('required')
              }"
            />
            <div
              *ngIf="
                forms['mobileNumber'].touched &&
                forms['mobileNumber'].hasError('required')
              "
              class="text-red-900 text-sm font-semibold w-full mt-1 ml-1"
            >
              {{ "Campo obligatorio" | translate }}
            </div>
          </div>

          <div style="width: 48%">
            <div class="relative z-30">
              <coface-select
                id-select="select-roles"
                id-option="option-roles"
                cofaceReactiveForm
                [valueTitle]="title4"
                [items]="itemsRoles"
                [value]="createUserPresenter.roleId.value"
                formControlName="roleId"
              ></coface-select>
            </div>
          </div>
        </div>

        <!-- flex ml-auto -->
        <div class="flex justify-between mb-5">
          <div *ngIf="disableLanguagePlatform" style="width: 48%">
            <div class="relative z-20">
              <coface-select
                id-select="select-languagePlatform"
                id-option="option-languagePlatform"
                cofaceReactiveForm
                [valueTitle]="title5"
                [items]="itemsLanguages"
                [value]="createUserPresenter.supportLanguageId.value"
                formControlName="supportLanguageId"
              ></coface-select>
              <!-- formControlName="roleId" -->
            </div>
          </div>
          <!-- {{ createUserPresenter.Form.valid }} -->
          <div class="ml-auto" style="width: 48%">
            <div
              class="input bg-coface text-center relative cursor-pointer"
              (click)="createUserStaff()"
              [class.spinner]="loaderBtnEvnt"
              [ngClass]="{ gray: createUserPresenter.invalid() }"
            >
              <p class="text-white">{{ "Crear nuevo usuario" | translate }}</p>
              <button
                type="button"
                class="absolute bg-white p-4 rounded-3xl btn__create"
              >
                <img
                  src="assets/img/upload/arrow-right.svg"
                  alt="Limpiar formulario"
                  rel="preload"
                  as="image"
                  class="w-4"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- {{ createUserPresenter.Form.invalid }}
{{createUserPresenter.Form.value | json }} -->
