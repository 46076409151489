import { Directive, forwardRef, HostListener, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

@Directive({
  selector: '[cofaceReactiveFile]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveFileDirective),
      multi: true,
    },
  ],
})
export class ReactiveFileDirective implements ControlValueAccessor {
  // tslint:disable-next-line: no-any
  private onChange: (value: any) => void;
  private onTouched: () => void;

  public constructor(private host: ElementRef<HTMLInputElement>) {}

  @HostListener('change', ['$event'])
  public _handleInputEvent(event: Event) {
    let file = (event.target as HTMLInputElement).files;
    if (file !== null) {
      let newfile = file[0] as File;
      const fileFormat = new File(
        [newfile],
        'coface-file'
          .concat(this.currentDate())
          .concat('.' + newfile.name.split('.').pop()!)
      );

      this.onChange(fileFormat);
      this.onTouched();
    }
  }

  private currentDate(): string {
    return moment().format('YYYY-MM-DD.HHmmssSSS');
  }

  // tslint:disable-next-line: no-any
  public registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public writeValue() {
    // clear file input
    // if(value instanceof File ){
    //   console.log(this.host.nativeElement, value);
    //   console.log('hola');
    //   this.host.nativeElement.value = (value as File).name;

    // }

    this.host.nativeElement.value = '';
  }
}
