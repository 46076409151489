import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { IDetail } from '../../../commons/interface/paginator-table.interface';
import { FilterModel } from '../../../commons/model/filter.model';
import { Paginator } from '../../../commons/model/paginator.model';
import { GenericRequestConfig } from '../../../commons/model/request.config';
import { ModalService } from '../../../commons/services/dialog.service';
import { IDetailPayment } from './disableUpload.interface';
import { HomologacionEndpoint } from './homologacion-enpoint';
import {
  Attachment,
  IEditInformationSupplier,
  IHomologationRequest,
  IObservations,
} from './homologacion-interface';
import { HomologationList } from './homologacion.paginator';

@Injectable({ providedIn: 'root' })
export class HomologacionOperation {
  idCondition: number;
  fullDocuments: boolean;
  informationPayed: string;
  countrySupplier: string;
  // infoSelectBasicForm: number | null;
  constructor(
    private homologationrEndpoint: HomologacionEndpoint,
    private session: SessionService,
    private dialog: ModalService
  ) {}

  public requestRecalification$(body: IHomologationRequest, idHomologation) {
    return this.homologationrEndpoint
      .requestRecalification$(body, idHomologation)
      .pipe();
  }

  public homologationInformation(idHomologation: number) {
    return this.homologationrEndpoint
      .homologationInformation$(idHomologation)
      .pipe();
  }

  public commentHomologationRejected(comment: string, reviewId: number) {
    const obj = { content: comment };
    return this.homologationrEndpoint
      .commentHomologationRejected$(obj, reviewId)
      .pipe();
  }

  public homologationPayed(body: IHomologationRequest, idHomologation) {
    return this.homologationrEndpoint
      .homologationPayed$(body, idHomologation)
      .pipe();
  }

  public homologationComplete(idCondition, idHomologation, score: string) {
    let objBody = {
      condition: {
        id: idCondition,
      },
      score: Number(score),
    };
    return this.homologationrEndpoint
      .homologationComplete$(objBody, idHomologation)
      .pipe();
  }

  public onDownloadFile$(idFile) {
    return this.homologationrEndpoint.downloadFile$(idFile).pipe();
  }

  public toSendObservations(body: IObservations, idHomologation) {
    const newBody = this.backBuild(body);
    return this.homologationrEndpoint
      .toSendObservations$(newBody, idHomologation)
      .pipe();
  }

  public toSendAnalystFromManager(idHomologation) {
    //    const newBody = this.backBuild(body);
    return this.homologationrEndpoint
      .toSendAnalystFromManager$(idHomologation)
      .pipe();
  }

  // delete
  public deleteAttachment(idFile: string) {
    return this.homologationrEndpoint.deleteAttachment$(idFile).pipe();
  }

  public reportsInPreparation(idCondition, idHomologation) {
    let objBody = {
      condition: {
        id: idCondition,
      },
      flagProdReq: idCondition === 9 ? true : null,
    };
    return this.homologationrEndpoint
      .reportsInPreparation$(objBody, idHomologation)
      .pipe();
  }

  public homologationCorrections(body, idHomologation) {
    return this.homologationrEndpoint
      .homologationCorrections$(body, idHomologation)
      .pipe();
  }

  public homologationVerifiyPayment(idHomologation: number) {
    return this.homologationrEndpoint
      .homologationVerifiyPayment$(idHomologation)
      .pipe();
  }

  public getNameManagerOfMyCustomer(idHomologation: number) {
    return this.homologationrEndpoint
      .getNameManagerOfMyCustomer$(idHomologation)
      .pipe();
  }

  public getNamePresentationLetter(idHomologation: number) {
    return this.homologationrEndpoint
      .getNamePresentationLetter$(idHomologation)
      .pipe();
  }

  //list homologation supplier
  public homologationSupplierList() {
    const req = new GenericRequestConfig();
    req.url = `${environment.urlBaseEndpointV2}/supplier/homologation-list`;
    req.params = new HttpParams()
      .set('user', this.session.user.id!) // this.session.user.id!
      .set('role', this.session.user.rolId!); // this.session.user.rolId! 5
    return this.homologationrEndpoint.homologationSupplierList$(req).pipe(
      map((res) => {
        const content = res.data['content'];
        let paginator = Paginator.backBuild<HomologationList>(res.data);
        if (content?.length) {
          let newData = content.map((elm) => HomologationList.backBuild(elm));
          paginator.Content = newData;
        }
        return paginator;
      })
    );
  }

  //observations
  private backBuild(body) {
    let obj: IObservations = {
      observation: body.observations,
      attachments: [],
    };
    body.folders.forEach((element, i) => {
      element.attachments.forEach((x) => {
        let obj2: Attachment = {
          id: x.id,
          comment: x.annotation,
        };
        obj.attachments.push(obj2);
      });
    });
    return obj;
  }

  //disable attachments
  public disableUploadHomologation(idHomologation: number) {
    return this.homologationrEndpoint
      .disableUploadHomologation$(idHomologation)
      .pipe();
  }

  //verify disable attachments
  public homologationEntity(idHomologation: number) {
    return this.homologationrEndpoint
      .homologationEntity$(idHomologation)
      .pipe();
  }

  //disable payment
  public disableIsPayedHomologation(idHomologation: number) {
    return this.homologationrEndpoint
      .disableIsPayedHomologation$(idHomologation)
      .pipe();
  }

  //disable attachments
  public unlockFileHomologation(idHomologation: number) {
    return this.homologationrEndpoint
      .unlockFileHomologation$(idHomologation)
      .pipe();
  }

  public paymentVerified(idHomologation: number) {
    return this.homologationrEndpoint.paymentVerified$(idHomologation).pipe();
  }

  public validRecalification(idHomologation: number) {
    return this.homologationrEndpoint
      .validRecalification$(idHomologation)
      .pipe();
  }

  public sentDocumentsToProduction(idHomologation: number) {
    return this.homologationrEndpoint
      .sentDocumentsToProduction$(idHomologation)
      .pipe();
  }

  public getAvailableDays(idHomologation: number) {
    return this.homologationrEndpoint.getAvailableDays$(idHomologation).pipe();
  }

  public selectBasicFormId(basicFormId: number) {
    return this.homologationrEndpoint.selectBasicFormId$(basicFormId).pipe();
  }

  public getPriceRateByCountry(idCountry: number, customerId: number) {
    return this.homologationrEndpoint
      .getPriceRateByCountry$(idCountry, customerId)
      .pipe();
  }

  public getInfoByUserDetail(idUser: number) {
    return this.homologationrEndpoint.getInfoByUserDetail$(idUser).pipe();
  }
  public buildWayToPay(country) {
    let obj = [] as IDetailPayment[];
    switch (country) {
      case 'Ecuador':
        obj = [
          {
            key: 'Titular de la cuenta',
            value: 'COFACE SERVICES ECUADOR S.A.',
          },
          { key: 'Banco', value: 'BANCO PICHINCHA' },
          { key: 'Cuenta No', value: '3054393004' },
          { key: 'CCI No', value: '3054393004' },
          { key: 'Swift', value: 'PICHECEQXXX' },
        ];
        break;
      case 'Mexico':
        obj = [
          { key: 'Banco', value: 'BANCO SANTANDER (MEXICO) S.A.' },
          { key: 'Cuenta No', value: '65505301608' },
          { key: 'CCI No', value: '014180655053016085' },
          { key: 'Numero de Sucursal', value: '0066' },
          { key: 'Tipo de cuenta', value: 'Cuenta de cheques' },
        ];
        break;

      case 'Chile':
        obj = [
          {
            key: 'Titular de la cuenta',
            value: 'COFACE SERVICIOS CHILE S.P.A.',
          },
          { key: 'Moneda', value: 'USD' },
          { key: 'Banco', value: 'Banco Santander Chile' },
          { key: 'Cuenta No', value: 'N° 510045573-2' },
          { key: 'CCI No', value: '014180655053016085' },
          { key: 'Swift', value: 'BSCHCLRM' },
        ];
        break;

      case 'Argentina':
        obj = [
          {
            key: 'Titular de la cuenta',
            value: 'COFACE SERVICIOS ARGENTINA SA',
          },
          { key: 'Moneda', value: 'Pesos argentinos' },
          { key: 'Cuenta No', value: 'Nº 000-34517/8' },
          { key: 'Banco', value: 'WELLS FARGO BANK N.A., NEW YORK, U.S.A.' },
          { key: 'ABA', value: '026005092' },
          { key: 'Swift', value: 'PNBPUS3NNYC' },
        ];
        break;
      case 'Colombia':
        obj = [
          {
            key: 'Titular de la cuenta',
            value: 'COFACE SERVICES COLOMBIA S.A',
          },
          { key: 'Banco', value: 'Banco de Bogota S.A. Miami Agency' },
          { key: 'Cuenta No', value: '91496' },
          { key: 'Ciudad/País', value: 'Miami, Estados Unidos' },
          { key: 'ABA', value: '066010720' },
          { key: 'Swift', value: 'BBOGUS3M' },
        ];
        break;
      case 'Peru':
        obj = [
          {
            key: 'Titular de la cuenta',
            value: 'COFACE SERVICES PERÚ S.A.',
          },
          { key: 'Banco', value: 'BANCO DE CRÉDITO DEL PERÚ' },
          { key: 'Cuenta No', value: '193-2248041-1-94' },
          { key: 'CCI No', value: '002-193-002248041194-15' },
          { key: 'Swift', value: 'BCPLPEPL' },
        ];
        break;
      default:
        obj = [
          {
            key: 'Titular de la cuenta',
            value: 'COFACE SERVICES ECUADOR S.A.',
          },
          { key: 'Banco', value: 'BANCO PICHINCHA' },
          { key: 'Cuenta No', value: '3054393004' },
          { key: 'CCI No', value: '3054393004' },
          { key: 'Swift', value: 'PICHECEQXXX' },
        ];
        break;
    }
    return obj;
  }

  // private builRequest(filter: FilterModel) {
  //   const rq = new GenericRequestConfig();
  //   rq.params = new HttpParams();
  //   rq.params = rq.params
  //     .set('page', filter.Page - 1)
  //     .set('size', filter.Size)
  //     .set('role', 5)
  //     .set('user', 1);
  //   return rq;
  // }

  public getInfoByParticipant(idHomologation: number) {
    return this.homologationrEndpoint
      .getInfoByParticipant$(idHomologation)
      .pipe();
  }

  // cancel homologation
  public cancelHomologation(idHomologation: number) {
    return this.homologationrEndpoint
      .cancelHomologation$(idHomologation)
      .pipe();
  }

  // Imposible de contactar
  public impossibleToContact(idHomologation: number, reachable: boolean) {
    return this.homologationrEndpoint
      .impossibleToContact$(idHomologation, reachable)
      .pipe();
  }

  // edit information supplier
  public editInformationSupplier(
    idHomologation: number,
    body: IEditInformationSupplier
  ) {
    return this.homologationrEndpoint
      .editInformationSupplier$(idHomologation, body)
      .pipe();
  }

  // terms and conditions upload documents
  public termsConditionsUploadDocuments(
    idHomologation: number,
    checkMarketing: boolean
  ) {
    return this.homologationrEndpoint
      .termsConditionsUploadDocuments$(idHomologation, checkMarketing)
      .pipe();
  }
}
